@import '_base.scss';

.Spacer {
    flex-grow: 1;
}

.HStack {
    display: flex;
}

.VStack {
    display: flex;
    flex-direction: column;
}
