@import '_base.scss';

.WikiList {
    padding-bottom: 0.75rem;

    &_container {
        padding-top: 1rem;

        &__isDashboard {
            height: 11rem;
            box-sizing: content-box;
            overflow-y: hidden;
        }
    }
}

.emptyMessage {
    margin-top: 2rem !important;
}

.FooterIcon {
    margin: 0 0.25rem;
}