@import '_base.scss';

.react-tabs {
    display: flex;
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;

    .react-tabs__tab-list {
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 3rem;
        margin: 0;
        padding: 0 0;
    }

    .react-tabs__tab {
        display: inline-block;
        border-left: .1875rem solid transparent;
        border-right: .1875rem solid transparent;
        border-bottom: none;
        cursor: pointer;
        font-weight: 500;
        height: 3rem;
        justify-content: center;
        list-style: none;
        line-height: 2.9375rem;
        padding: 0 .625rem;
        position: relative;
        border-bottom: .0625rem solid transparent;
        white-space: nowrap;

        &--selected {
            border-bottom: .1875rem solid $selected-color;
            color: $selected-color;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            box-shadow: 0 0 5px hsl(208, 99%, 50%);
            border-color: hsl(208, 99%, 50%);
            outline: none;
        }

        &:hover {

            border-bottom: .1875rem solid hsla(215, 79%, 46%, 0.6);
            color: hsla(215, 79%, 46%, 0.6);
        }
    }

    .react-tabs__tab-panel {
        display: none;
        height: 100%;
        padding: 0;

        &>*:first-child {
            padding-top: 0;
            margin-top: 0;
        }

        &--selected {
            display: block;
        }
    }
}

.react-tabs-vertical {
    display: flex;
    -webkit-tap-highlight-color: transparent;

    .react-tabs__tab-list {
        display: flex;
        border-right: 1px solid $border-color;
        flex: 0 0 192px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 192px;
        flex-direction: column;
        margin: 0 0 0 -0.75rem;
        padding: 0;
    }

    .react-tabs__tab {
        display: inline-block;
        border-bottom: none;
        bottom: -1px;
        justify-content: center;
        list-style: none;
        padding: 0.75rem 1px 0.75rem 0.75rem;
        position: relative;
        cursor: pointer;

        &--selected {
            border-color: #aaa;
            color: $selected-color;
            font-weight: 500;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            box-shadow: 0 0 5px hsl(208, 99%, 50%);
            border-color: hsl(208, 99%, 50%);
            outline: none;
        }

        &:hover {
            background: $hover-background;
        }
    }

    .react-tabs__tab-panel {
        display: none;
        height: 100%;
        padding: 0 1rem 0 1.5rem;

        &>*:first-child {
            padding-top: 0;
            margin-top: 0;
        }

        &--selected {
            display: block;
        }
    }
}