@import '_base.scss';

.Card {
    border: 1px solid $border-color;
    border-radius: 4px;
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 256px;

    &:hover {
        border-color: $selected-color;
    }
}

.CardBody {
    height: 1.5rem;
    line-height: 1.5;
    overflow: hidden;
    padding: 0 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CardFooter,
.CardHeader {
    padding: 1rem;
}

.CardHeader {
    align-items: center;
    display: flex;

    &_avatar {
        align-items: center;
        background-color: $logo-yellow;
        border-radius: 100px;
        color: white;
        display: flex;
        font-size: 1.6rem;
        font-weight: 500;
        height: 2.5rem;
        justify-content: center;
        margin-right: 16px;
        text-align: center;
        vertical-align: middle;
        width: 2.5rem;
    }

    &_main {
        &_subtitle {
            color: $button-grey;
        }

        &_title {
            color: black;
        }
    }
}

.CardFooter {
    align-items: flex-end;
    color: $button-grey;
    display: flex;
}