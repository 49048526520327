@import '_base.scss';

.GoogleButton {
    align-items: center;
    background-color: $primary-main;
    border: 1px solid $primary-main;
    border-radius: 2px;
    box-shadow: $shadow-1;
    cursor: pointer;
    display: flex;
    height: 40px;
    max-width: 214px;
    transition-delay: 0s;
    transition-duration: 300ms;
    transition-property: background-color, border;
    transition-timing-function: ease-out;
    width: fit-content;
    &:hover {
        background-color: $primary-dark;

        border: 1px solid $primary-dark;
    }
    &_icon {
        align-items: center;
        background-color: white;
        display: flex;
        height: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
    &_text {
        color: white;
        font-weight: 500;
        padding-left: 12px;
        padding-right: 16px;
    }
    svg {
        height: 18px;
        width: 18px;
    }
}
