@import '_base.scss';

.Tooltip {
    & > span:first-child {
        background-color: $tooltip-background;
        color: white;
        border: 0;
        border-radius: 2px;
        box-sizing: border-box;
        font-weight: 500;
        margin-left: 0;
        margin-top: -2px;
        min-height: 22px;
        min-width: 56px;
        opacity: 0.9;
        padding: 5px 8px !important;
        text-align: center;
        white-space: nowrap;
    }
}
