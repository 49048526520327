@import '_base.scss';

.Modal {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: $common-border-radius;
    box-shadow: $shadow-2;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 4rem);
    outline: thin dotted;
    width: calc(100% - 4rem);
    @media (max-width: $md-max) {
        display: block;
        width: 100%;
        max-height: none;
    }
    &_content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 1rem 2rem;
        & > *:first-child {
            margin-top: 0;
        }
    }
    &_footer {
        align-items: flex-start;
        display: flex;
        justify-content: flex-end;
        height: 64px;
        padding: 0 2rem;
        > * {
            margin-left: 0.5rem;
        }
    }
    &_header {
        align-items: flex-end;
        display: flex;
        height: 64px;
        min-height: 64px;
        padding: 0 1.5rem 0 2rem;
        &_title {
            -webkit-font-smoothing: antialiased;
            flex-grow: 1;
            font-size: 1.375rem;
        }
    }
    &__xs {
        max-width: 444px;
    }
    &__sm {
        max-width: 600px;
    }
    &__md {
        max-width: 960px;
    }
    &__lg {
        max-width: 1280px;
    }
    &__xl {
        max-width: 1920px;
    }
    &__fullHeight {
        height: 100%;
    }
}

.Overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.405);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
    @media (max-width: $md-max) {
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        display: block;
        align-items: start;
        justify-content: start;
    }
}
