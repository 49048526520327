@import './static/styles/breakpoints';
@import './static/styles/palette';

$border-radius-pill: 66px;
$border-color: #d5d5d5;
$button-grey: #5f6368;
$common-border-radius: 4px;
$container-width-large: 1280px;
$hover-background: #eee;
$search-border-radius: 8px;
$selected-background: #e8f0fe;
$selected-color: hsl(215, 79%, 46%);
$shadow-1: rgba(65, 69, 73, 0.3) 0px 1px 1px 0px,
    rgba(65, 69, 73, 0.15) 0px 1px 3px 1px;
$shadow-2: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
$sidebar-width: 256px;
$text-color: #3c4043;
$tooltip-background: #616161;

$logo-blue: hsl(217, 89%, 61%);
$logo-red: hsl(5, 81%, 56%);
$logo-green: hsl(136, 53%, 43%);
$logo-yellow: hsl(45, 97%, 50%);
