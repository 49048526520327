table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid black;
}

table tr {
    border: none;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

table thead tr {
    background: #f5f5f5;
    font-weight: bold;
}

table td,
table th {
    border: 1px solid black;
    border-top: none;
    border-bottom: none;
    border-right: none;
    padding: 0.5em;
    position: relative;
    text-align: left;
}

/* for nested tables */
table td > table {
    width: 100%;
}

td > p:first-child {
    margin-top: 0;
}

td > p:last-child {
    margin-bottom: 0;
}
