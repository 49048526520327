@import '_base.scss';

.call-to-action {
    bottom: 20px;
    position: fixed;
    right: 20px;
    @media (max-width: $sm) {
        display: none;
    }
}

.columns {
    @media (min-width: $lg) {
        display: flex;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        margin-top: -1.5rem;
        .col {
            display: flex;
            flex-basis: 0;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            justify-content: center;
            padding: 1.5rem;
        }
    }
    .col {
        margin: 0 auto;
    }
}

.frontpage-container {
    margin: 3rem 0;
    padding: 0 6%;
    @media (min-width: $sm) {
        margin: 8rem 0;
        padding: 0 6%;
    }
}

.frontpage__header {
    font-size: 42px;
    font-weight: 500;
    line-height: 1.2;
    max-width: 45rem;
}

.hero {
    padding: 3rem 2rem;
    margin-top: -0.5rem;

    @media (min-width: $sm) {
        padding: 5rem 6%;
    }
    &__max1280 {
        max-width: 1280px;
        margin: 0 auto;
    }
    .hero_logo {
        padding-top: 80px;
        margin-bottom: 20px;
    }
}

.hidden {
    display: none;
    &__sm {
        @media (max-width: $sm - 1) {
            display: none;
        }
    }
    &__md {
        @media (max-width: $md - 1) {
            display: none;
        }
    }
    &__lg {
        @media (max-width: $lg - 1) {
            display: none;
        }
    }
}

.show {
    display: inherit;
    &__sm {
        display: none;
        @media (max-width: $sm - 1) {
            display: inherit;
        }
    }
    &__md {
        display: none;
        @media (max-width: $md - 1) {
            display: inherit;
        }
    }
    &__lg {
        display: none;
        @media (max-width: $lg - 1) {
            display: inherit;
        }
    }
}
.md {
    @media (min-width: $md) {
        display: flex;
        &.hidden {
            display: none;
        }
    }
}

.sm {
    @media (min-width: $sm) {
        display: none;
    }

    &.hidden {
        display: none;
    }
}

.shadow {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    max-width: 100%;
    width: max-content;
}

.SignInWithGoogle:hover {
    background-color: rgb(46, 93, 170) !important;
}
.SignInWithGoogle svg {
    height: 18px;
    width: 18px;
}

.why-icon-ontainer {
    align-items: center;
    display: flex;
    flex-direction: column;
}
