@import '_base.scss';

.Paper {
    background-color: $common-white;
    border-radius: $common-border-radius;
    box-shadow: $shadow-2;
    left: 2rem;
    min-width: 112px;
    outline: 1px solid transparent;
    padding: 6px 0;
    position: absolute;
    top: 4.3rem;
}

.Paper_menuEntry {
    cursor: pointer;
    display: block;
    color: $common-black;
    font-family: 'Roboto';
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 20px;
    outline: none;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    &:hover {
        background-color: $grey-200;
    }
}
