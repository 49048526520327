$common-black: #000;
$common-white: #fff;
$primary-main: #4285f4;
$primary-light: rgb(103, 157, 246);
$primary-dark: rgb(46, 93, 170);
$primary-contrastText: #fff;
$secondary-main: #ea4335;
$secondary-light: rgb(238, 104, 93);
$secondary-dark: rgb(163, 46, 37);
$secondary-contrastText: #fff;
$error-light: #e57373;
$error-main: #f44336;
$error-dark: #d32f2f;
$error-contrastText: #fff;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd; 
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$grey-A100: #d5d5d5;
$grey-A200: #aaaaaa;
$grey-A400: #303030;
$grey-A700: #616161;
$grey-foreground: #6e6f70;
$contrastThreshold: 3;
$tonalOffset: 0.2;
$text-primary: #3c4043;
$text-secondary: rgba(0, 0, 0, 0.54);
$text-disabled: rgba(0, 0, 0, 0.38);
$text-hint: rgba(0, 0, 0, 0.38);
$divider: rgba(0, 0, 0, 0.12);
$background-paper: #fff;
$background-default: #fff;
$background-alternative: #f1f3f4;
$action-active: rgba(0, 0, 0, 0.54);
$action-hover: rgba(0, 0, 0, 0.08);
$action-hoverOpacity: 0.08;
$action-selected: rgba(0, 0, 0, 0.14);
$action-disabled: rgba(0, 0, 0, 0.26);
$action-disabledBackground: rgba(0, 0, 0, 0.12);
