@import '_base.scss';

.IconButton {
    background-color: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    color: $grey-700;
    cursor: pointer;
    display: inline-flex;
    margin: 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1),
        background 200ms cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 50%;
    min-width: 0;
    overflow: visible;
    height: 40px;
    width: 40px;
    padding: 2px;
    position: relative;
    user-select: none;
    vertical-align: top;
    &:hover {
        background-color: $background-alternative;
        border: 1px solid transparent;
        color: #3c4043;
    }
    &__selected {
        background-color: $selected-background;
        color: $selected-color;
    }
}

.IconButton_inner {
    left: 50%;
    line-height: 0;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
}
