@import '_base.scss';

.Fab {
    color: $common-white;
    display: none;
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    visibility: visible;
    z-index: 1;
    @media (max-width: $md-max) {
        display: block;
    }
}

.Sidebar_newButton {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
        0 1px 3px 1px rgba(60, 64, 67, 0.149);
    align-items: center;
    background-color: #fff;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 24px;
    color: #3c4043;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin: 1rem 0 0 calc(1rem - 5px);
    min-width: 120px;
    padding: 0 1rem;
    text-transform: none;
    width: inherit;
    cursor: pointer;
    @media (max-width: $md-max) {
        display: none;
    }
    &:hover {
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
            0 4px 8px 3px rgba(60, 64, 67, 0.149);
        background-color: #f8f9fa;
        outline: none;
    }
}

.Sidebar_navigation {
    height: calc(100vh - 128px);
    &:hover {
        overflow-y: auto;
    }
}

.svg {
    fill: $secondary-main;
    padding-right: 0.5rem;
    width: 2.5rem;
}
