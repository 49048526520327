@import 'static/styles/_palette.scss';
@import 'static/styles/_breakpoints.scss';

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background-color: $background-default;
    color: $text-primary;
    margin: 0;
    font-family: Roboto, 'Open Sans', -apple-system, BlinkMacSystemFont,
        Avenir Next, Avenir, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.2px;
    overflow: hidden;
}

:-moz-focusring {
    outline-width: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4,
h5 {
    color: #202124;
    font-weight: 400;
}

h1 {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 0 0 0.5rem;
}
@media (min-width: 48.1875rem) {
    h1 {
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0 0 0.5rem;
    }
}

h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 2rem 0 0.5rem;
}

h3 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin: 1.5rem 0 0.5rem;
}

h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 1.25rem 0 0.25rem;
}

h5 {
    font-size: 0.875rem;
    font-weight: inherit;
    line-height: 1.5rem;
    margin: 1rem 0 0.25rem;
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.container {
    &__max1280 {
        margin: 0 auto;
        max-width: 1280px;
    }
    &__max640 {
        margin: 0 auto;
        max-width: 640px;
    }
}
