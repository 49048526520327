@import '_base.scss';

.step__wrapper {
    padding: 5rem;
}
.step {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.step__icon {
    fill: $primary-main;
}
.step__icon-wrapper {
    align-items: center;
    border-width: 4px;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    display: flex;
    height: 96px;
    justify-content: center;
    margin-top: 3rem;
    text-align: center;
    width: 96px;
}
