@import '_base.scss';

.Button {
    color: $button-grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    width: fit-content;
    transition-delay: 0s;
    transition-duration: 300ms;
    transition-property: background-color;
    transition-timing-function: ease-out;
    border-radius: 4px;
    padding: 0.5rem 1.125rem;
    &:hover {
        background-color: $hover-background;
    }
    &__primary {
        background-color: $primary-main;
        color: $primary-contrastText;
        &:hover {
            background-color: $primary-dark;
        }
    }
}
