$xs: 0px;
$sm: 600px;
$md: 950px;
$lg: 1280px;
$xl: 1920px;

/* for max width */
$sm-max: $sm - 1;
$md-max: $md - 1;
$lg-max: $lg - 1;
$xl-max: $xl - 1;
