@import '_base.scss';

.Search {
    align-items: center;
    border-radius: 8px;
    background-color: $background-alternative;
    flex-grow: 1;
    display: flex;
    height: 3rem;
    margin-right: 1rem;
    margin-left: 0;
    max-width: 720px;
    position: relative;
    width: 100%;
    @media (max-width: $md - 1) {
        margin-right: 0.5rem;
    }

    &__active {
        background: $common-white;
        border: 1px solid transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: $shadow-1;
        &__empty {
            border-bottom-left-radius: $search-border-radius;
            border-bottom-right-radius: $search-border-radius;
            @media (max-width: $md - 1) {
                border-radius: 0;
            }
        }
        @media (max-width: $md - 1) {
            background: $common-white;
            border-radius: 0;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            height: 56px;
            left: 0;
            max-width: 100vw;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 1000;
        }
    }

    &_mobileSearchCover {
        @media (max-width: $md - 1) {
            background-color: $common-white;
            height: 100vh;
            left: 0;
            position: absolute;
            top: 56px;
            width: 100vw;
        }
    }
}

.Search_end,
.Search_middle,
.Search_start {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Search_end {
    width: 49px;
}
.Search_middle {
    align-items: flex-start;
    flex-grow: 1;
}

.Search_start {
    padding: 0 5px;
    width: 56px;
}
.Search_Icon {
    margin-top: 1px;
    margin-left: 5px;
    height: 40px;
    width: 40px;
    @media (max-width: $md - 1) {
        display: none;
        width: 3.5rem;
        height: 100%;
        margin-top: 5px;
        position: absolute;
        pointer-events: none;
        justify-content: center;
    }
    @media (min-width: $sm) and (max-width: $md - 1) {
        margin-top: 0;
        align-items: center;
    }
}

.Search_input {
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 1rem;
    height: auto;
    margin: 0px;
    /* because of :-moz-focusring from normalize.css */
    outline: none !important;
    padding: 0px;
    width: 100%;
}

.input_root {
    color: inherit;
    width: 100%;
}

.backIcon {
    @media (max-width: $md - 1) {
        left: 6px;
        position: fixed;
        top: 7px;
        z-index: 10001;
    }
    @media (min-width: $md) {
        display: none;
    }
}

.input_input {
    color: $common-black;
    width: 100%;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 6px 8px 8px;
}
