@import '_base.scss';

.Navbar {
    background-color: $common-white;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    height: 64px;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.Navbar_actions,
.Navbar_logoContainer,
.Navbar_spacer {
    align-items: center;
    display: flex;
}

.Navbar_actions {
    padding-right: 1.5rem;

    @media (max-width: $md - 1) {
        padding-right: 0.5rem;
    }
}

.Navbar_logo {
    max-height: 24px;
    margin-right: 0.75rem;

    @media (min-width: $md) {
        max-height: 40px;
    }
}

.Navbar_logoContainer {
    color: $text-primary;
    font-size: 1.25rem;
    font-weight: 400;
    padding-left: 1.5rem;
    text-decoration: none;

    &__isSignedIn {
        display: none;

        @media (min-width: $md) {
            display: flex;
            width: $sidebar-width;
        }
    }
}

.Navbar_menu {
    display: none;
    padding: 0 0.5rem;

    @media (max-width: $md-max) {
        display: flex;
        align-items: center;
    }
}

.Navbar_spacer {
    flex-grow: 1;
}

.AppBar {
    background: white;
    border-bottom: 1px solid $grey-A100;
    box-shadow: none;
}

.AppBar__signedIn {
    background: white;
    box-shadow: none;

    @media (min-width: $md) {
        border-bottom: 1px solid $grey-A100;
    }
}

.card {
    margin: 0.5rem 1rem;

    @media (min-width: $md) {
        margin: 0;
        box-shadow: none;
    }
}

.grow {
    flex-grow: 1;
}

.title {
    display: block;
    text-decoration: none;
}

.title__signedIn {
    display: none;

    @media (min-width: $md) {
        display: block;
        text-decoration: none;
    }
}

.title_wrapper {
    display: flex;
    color: $button-grey;
    align-items: center;

    @media (min-width: $md) {
        min-width: 236px;
    }
}

.Toolbar {
    @media (min-width: $md) {
        min-height: 64px;
        padding-left: 20px;
        padding-right: 20px;
    }
}